import { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Upload, message } from "antd";
import { PBox, PText } from "../theme/BaseTheme";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const MockupCropperModal = (props) => {
    const [uploadedImage, setUploadedImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const cropperRef = useRef(null);

    const onCrop = () => {
        setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    };

    const handlePreview = async ({ file }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setUploadedImage(file.url || file.preview);
    };
    return (
        <Modal
            title={"Upload A Design Image"}
            open={props.isMockupCropperModalOpen}
            onOk={async () => {
                props.handleMockupCropperModalOk(cropData);
            }}
            onCancel={props.handleMockupCropperModalCancel}
            okText={"Confirm"}
            width={600}
        >
            <PBox css={styles}>
                <Upload.Dragger
                    onChange={handlePreview}
                    showUploadList={false}
                    listType="picture-card"
                    maxCount={1}
                    beforeUpload={(file) => {
                        const isJpgOrPng =
                            file.type === "image/jpeg" ||
                            file.type === "image/png";
                        if (!isJpgOrPng) {
                            message.error("You can only upload JPG/PNG file.");
                        }
                        const isLt2M = file.size / 1024 / 1024 < 2;
                        if (!isLt2M) {
                            message.error("Image must smaller than 2MB.");
                        }
                        return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE;
                    }}
                >
                    <>Click or drag to upload</>
                </Upload.Dragger>
                {uploadedImage && <PText></PText>}
                <Cropper
                    src={uploadedImage}
                    style={{ height: 400, width: "100%" }}
                    // Cropper.js options
                    initialAspectRatio={1}
                    guides={false}
                    crop={onCrop}
                    autoCropArea={1}
                    ref={cropperRef}
                />
            </PBox>
        </Modal>
    );
};

const styles = {
    "& .app-content-mockups": {
        margin: "1rem 0",
        overflow: "scroll",
        height: "500px",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "& .app-content-mockups-item": {
            position: "relative",
            "& img": {
                maxWidth: `calc(100% - 1rem)`,
                margin: "0.5rem auto",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
            },
            "& svg": {
                position: "absolute",
                top: "5%",
                right: "5%",
                fill: "#d48806",
                backgroundColor: "#ffe58f",
                padding: "0.125rem 0.25rem",
                borderRadius: "0.25rem",
                width: "1rem",
                height: "1rem",
            },
        },
    },
    "& .mockup-img-active": {
        border: "0.25rem solid #6d28d9",
        padding: "0.25rem",
    },
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {})(withRouter(MockupCropperModal));
