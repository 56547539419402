import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tag } from "antd";
import { PBox, PH1, PImg, PLink, PText, PH4 } from "../theme/BaseTheme";
import { Layout } from "../pages/landing/Layout";
import { Loader } from "../components/Loader";
import { fetchMockupTemplates } from "../../actions/pattern";
import { mockupBaseUrl, mockupOptions } from "./Constants";

class MockupGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            category: this.props.match.params.cat,
        };
    }
    async componentDidMount() {
        await this.props.fetchMockupTemplates(this.state.category);
        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Mockup Gallery</title>
                    <meta
                        name="description"
                        content="Showcase seamless patterns generated by Patternful.ai. Whether you're looking to enhance textiles, digital content, or print media, generated patterns ensure your work stands out with a unique and professional aesthetic."
                    />
                    <meta
                        name="keywords"
                        content="Seamless Pattern Generator, Seamless Pattern AI, Seamless Pattern Maker, Seamless Pattern APP, Seamless Pattern Online Generator, Print On Demand, Pattern Design, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="mockup-gallery-title">
                        <PH1>Mockup Gallery</PH1>

                        <PText>
                            Select a mockup template to begin creating and
                            customizing your mockup images.
                        </PText>
                        <PBox>
                            Categories:{" "}
                            {mockupOptions.map((option) => {
                                return (
                                    <Tag
                                        color={
                                            this.state.category == option.value
                                                ? "volcano"
                                                : "purple"
                                        }
                                        onClick={() => {
                                            window.location.href = `/mockup-gallery/${option.value}`;
                                        }}
                                    >
                                        {option.label}
                                    </Tag>
                                );
                            })}
                        </PBox>
                    </PBox>
                    <PBox className="mockup-gallery-container">
                        {this.props.mockupTemplates?.map((template, id) => {
                            return (
                                <PBox className="mockup-gallery-image">
                                    <PImg
                                        alt="patternful ai mockup template"
                                        src={`${mockupBaseUrl}/${template?.uuid}.jpg`}
                                        crossorigin={
                                            process.env.NODE_ENV ===
                                            "development"
                                                ? "anonymous"
                                                : ""
                                        }
                                        onClick={() => {
                                            window.location.href = `/mockup/app/${template.uuid}`;
                                        }}
                                    />
                                </PBox>
                            );
                        })}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    padding: "0 1.5rem",
    maxWidth: "80rem",
    margin: "2rem auto",
    "@bp2": { marginTop: "4rem" },
    "@bp4": { paddingLeft: "2rem", paddingRight: "2rem" },
    "& h1": { textAlign: "center", marginBottom: "2rem" },
    "& .mockup-gallery-container": {
        margin: "0 auto 6rem auto",
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "@bp2": { gridTemplateColumns: "repeat(3, minmax(0, 1fr))" },
        "@bp4": { gridTemplateColumns: "repeat(4, minmax(0, 1fr))" },
    },
    "& .mockup-gallery-title": {
        textAlign: "center",
        marginBottom: "3rem",
        "& a": {
            textDecoration: "none",
            color: "$colors$primary",
            fontWeight: "500",
        },
        "& span": {
            cursor: "pointer",
        },
    },
    "& .mockup-gallery-image": {
        position: "relative",
        overflow: "hidden",
        border: "1px solid $colors$borderColor",
        borderRadius: "0.5rem",
        "& img": {
            width: "100%",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            display: "block",
            "&:hover": {
                cursor: "pointer",
            },
        },
        "& .mockup-gallery-prompt": {
            position: "absolute",
            left: "0",
            bottom: "0",
            padding: "0 1rem",
            margin: "0",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            opacity: "0",
            visibility: "hidden",
            "-webkit-transition": "all .5s ease",
            "-moz-transition": "all .5s ease",
            transition: "all .5s ease",
            "& p": {
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                display: "-webkit-box",
            },
        },
    },
    "& .mockup-gallery-image:hover": {
        "& img": { transform: "scale(1.1)" },
        "& .mockup-gallery-prompt": { opacity: "1", visibility: "visible" },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        mockupTemplates:
            state.patternReducer?.fetchMockupTemplates?.mockup_templates,
    };
}

export default connect(mapStateToProps, { fetchMockupTemplates })(
    withRouter(MockupGallery)
);
