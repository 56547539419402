import { combineReducers } from "redux";

const fetchMockupTemplate = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_MOCKUP_TEMPLATE_SUCCESS":
            return {
                isLoading: false,
                isError: false,
                isSuccess: true,
                mockup_template: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({ fetchMockupTemplate });
