import React, { Component } from "react";
import ImageEditor from "@toast-ui/react-image-editor";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import download from "downloadjs";
import clsx from "clsx";
import { Radio, Select, Button, InputNumber, Card, message, Image } from "antd";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PH4, PH3, PImg, PText } from "../../theme/BaseTheme";
import { Dimension } from "../../components/app/Dimension";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";
import { Crop } from "../../sections/Crop";
import {
    fetchPattern,
    downloadEditedImage,
    generateMockup,
    clearStoredMockup,
    fetchMockupTemplates,
} from "../../../actions/pattern";
import { refetchUser } from "../../../actions/auth";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import { mockupBaseUrl } from "../../mockup/Constants";

const myTheme = {
    // download button
    "downloadButton.display": "none",
    // load button
    "loadButton.display": "none",
    // header
    "header.display": "none",

    "common.bi.image":
        "https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png",
    "common.bisize.width": "251px",
    "common.bisize.height": "21px",
    "common.backgroundImage": "./img/bg.png",
    "common.backgroundColor": "#fff",
    "common.border": "1px solid #c1c1c1",

    // header
    "header.backgroundImage": "none",
    "header.backgroundColor": "transparent",
    "header.border": "0px",

    // load button
    "loadButton.backgroundColor": "#fff",
    "loadButton.border": "1px solid #ddd",
    "loadButton.color": "#222",
    "loadButton.fontFamily": "'Noto Sans', sans-serif",
    "loadButton.fontSize": "12px",

    // download button
    "downloadButton.backgroundColor": "#fdba3b",
    "downloadButton.border": "1px solid #fdba3b",
    "downloadButton.color": "#fff",
    "downloadButton.fontFamily": "'Noto Sans', sans-serif",
    "downloadButton.fontSize": "12px",

    // main icons
    "menu.normalIcon.color": "#8a8a8a",
    "menu.activeIcon.color": "#555555",
    "menu.disabledIcon.color": "#434343",
    "menu.hoverIcon.color": "#e9e9e9",
    "menu.iconSize.width": "24px",
    "menu.iconSize.height": "24px",

    // submenu icons
    "submenu.normalIcon.color": "#8a8a8a",
    "submenu.activeIcon.color": "#555555",
    "submenu.iconSize.width": "32px",
    "submenu.iconSize.height": "32px",

    // submenu primary color
    "submenu.backgroundColor": "transparent",
    "submenu.partition.color": "#e5e5e5",

    // submenu labels
    "submenu.normalLabel.color": "#858585",
    "submenu.normalLabel.fontWeight": "normal",
    "submenu.activeLabel.color": "#000",
    "submenu.activeLabel.fontWeight": "normal",

    // checkbox style
    "checkbox.border": "1px solid #ccc",
    "checkbox.backgroundColor": "#fff",

    // rango style
    "range.pointer.color": "#333",
    "range.bar.color": "#ccc",
    "range.subbar.color": "#606060",

    "range.disabledPointer.color": "#d3d3d3",
    "range.disabledBar.color": "rgba(85,85,85,0.06)",
    "range.disabledSubbar.color": "rgba(51,51,51,0.2)",

    "range.value.color": "#000",
    "range.value.fontWeight": "normal",
    "range.value.fontSize": "11px",
    "range.value.border": "0",
    "range.value.backgroundColor": "#f5f5f5",
    "range.title.color": "#000",
    "range.title.fontWeight": "lighter",

    // colorpicker style
    "colorpicker.button.border": "0px",
    "colorpicker.title.color": "#000",
};

const mockupOptions = [
    { value: "technology", label: "Technology" },
    { value: "print", label: "Print" },
    { value: "packaging", label: "Packaging" },
    { value: "apparel", label: "Apparel" },
    { value: "home_and_living", label: "Home & Living" },
];

class Design extends Component {
    editorRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            control: "design",
            usage: "pod",
            upscale: "1",
            croppedImage: null,
            dpi: "72",
            format: "png",
            width: "undefined",
            height: "undefined",
            isProcessing: false,
            isApplyingMockup: false,
            mockupUUID: "",
            mockupCredits: 0,
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            newPlan: "",
        };
        this.downloadImage = this.downloadImage.bind(this);
    }
    async componentDidMount() {
        await this.props.fetchPattern(this.props.match.params.pattern_uuid);
        await this.props.clearStoredMockup();
        await this.setState({ isLoading: false });
    }

    downloadImage = async () => {
        await this.setState({ isProcessing: true });
        message.open({
            type: "loading",
            content: "Preparing for downloading. This can take a while.",
            duration: 0,
        });
        const editorInstance = await this.editorRef.current.getInstance();
        let dataURL = await editorInstance.toDataURL();
        await this.props.downloadEditedImage(
            this.props.match.params.pattern_uuid,
            dataURL,
            this.state.format,
            this.state.dpi,
            this.state.width,
            this.state.height,
            this.state.upscale
        );
        message.destroy();
        await this.setState({ isProcessing: false });
    };

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <PBox className="app-content-control">
                            {this.state.control === "design" && (
                                <Card
                                    title={
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "Edit",
                                                    value: "design",
                                                },
                                                {
                                                    label: "Mockup",
                                                    value: "mockup",
                                                },
                                            ]}
                                            value={this.state.control}
                                            onChange={(e) => {
                                                this.setState({
                                                    control: e.target.value,
                                                });
                                            }}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    }
                                    //title="Edit and Download"
                                >
                                    <PBox>
                                        <PH3>Download Options</PH3>
                                        <PH4>1. Please choose a dimension.</PH4>
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "Predefined dimensions.",
                                                    value: "pod",
                                                },
                                                {
                                                    label: "Customized dimensions.",
                                                    value: "selling",
                                                },
                                            ]}
                                            value={this.state.usage}
                                            onChange={(e) => {
                                                this.setState({
                                                    usage: e.target.value,
                                                });
                                            }}
                                        />
                                        {this.state.usage === "pod" && (
                                            <Dimension
                                                setWidth={(value) => {
                                                    this.setState({
                                                        width: value,
                                                    });
                                                }}
                                                setHeight={(value) => {
                                                    this.setState({
                                                        height: value,
                                                    });
                                                }}
                                            />
                                        )}
                                        {this.state.usage === "selling" && (
                                            <>
                                                <PText>Width</PText>
                                                <InputNumber
                                                    min={1}
                                                    max={999999}
                                                    value={this.state.width}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            width: value,
                                                        });
                                                    }}
                                                />{" "}
                                                px
                                                <PText>Height</PText>
                                                <InputNumber
                                                    min={1}
                                                    max={999999}
                                                    value={this.state.height}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            height: value,
                                                        });
                                                    }}
                                                />{" "}
                                                px
                                            </>
                                        )}
                                    </PBox>
                                    <PBox>
                                        <PH4>
                                            2. Please choose a resolution
                                            (upscale image).
                                        </PH4>
                                        <Radio.Group
                                            value={this.state.upscale}
                                            onChange={(e) => {
                                                this.setState({
                                                    upscale: e.target.value,
                                                });
                                            }}
                                            options={[
                                                {
                                                    label: "x1 (1024px x 1024px)",
                                                    value: "1",
                                                },
                                                {
                                                    label: "x2 (2048px x 2048px)",
                                                    value: "2",
                                                },
                                                {
                                                    label: "x4 (4096px x 4096px)",
                                                    value: "4",
                                                },
                                            ]}
                                        />
                                    </PBox>
                                    <PBox>
                                        <PH4>3. Please choose a DPI.</PH4>
                                        <Radio.Group
                                            value={this.state.dpi}
                                            onChange={(e) => {
                                                this.setState({
                                                    dpi: e.target.value,
                                                });
                                            }}
                                            options={[
                                                {
                                                    label: "72 dpi",
                                                    value: "72",
                                                },
                                                {
                                                    label: "100 dpi",
                                                    value: "100",
                                                },
                                                {
                                                    label: "150 dpi",
                                                    value: "150",
                                                },
                                                {
                                                    label: "200 dpi",
                                                    value: "200",
                                                },
                                                {
                                                    label: "300 dpi",
                                                    value: "300",
                                                },
                                            ]}
                                        />
                                    </PBox>
                                    <PBox>
                                        <PH4>4. Please choose a format.</PH4>
                                        <Radio.Group
                                            value={this.state.format}
                                            onChange={(e) => {
                                                this.setState({
                                                    format: e.target.value,
                                                });
                                            }}
                                            options={[
                                                {
                                                    label: "PNG",
                                                    value: "png",
                                                },
                                                {
                                                    label: "JPEG",
                                                    value: "jpg",
                                                },
                                                {
                                                    label: "WEBP",
                                                    value: "webp",
                                                },
                                            ]}
                                        />
                                    </PBox>
                                    {/*<PText>Preview</PText>
                                            <PBox>
                                        <PImg
                                            src={this.state.croppedImage}
                                            width={200}
                                        />
                                    </PBox>*/}
                                    <PBox>
                                        <PText>
                                            <br />
                                        </PText>
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                await this.downloadImage();
                                            }}
                                            loading={this.state.isProcessing}
                                        >
                                            Download
                                        </Button>
                                    </PBox>
                                </Card>
                            )}
                            {this.state.control === "mockup" && (
                                <Card
                                    title={
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "Design",
                                                    value: "design",
                                                },
                                                {
                                                    label: "Mockup",
                                                    value: "mockup",
                                                },
                                            ]}
                                            value={this.state.control}
                                            onChange={(e) => {
                                                this.setState({
                                                    control: e.target.value,
                                                });
                                            }}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    }
                                >
                                    <PBox>
                                        Please select a mockup image to apply.
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            options={mockupOptions}
                                            onChange={async (value) => {
                                                await this.props.fetchMockupTemplates(
                                                    value
                                                );
                                            }}
                                            defaultValue={"print"}
                                        />
                                        <PBox className="app-content-mockups">
                                            {this.props.mockup_templates?.map(
                                                (template) => {
                                                    return (
                                                        <PBox className="app-content-mockups-item">
                                                            <PImg
                                                                alt="patternful ai"
                                                                className={clsx(
                                                                    this.state
                                                                        .mockupUUID ===
                                                                        template?.uuid
                                                                        ? "mockup-img-active"
                                                                        : ""
                                                                )}
                                                                src={`${mockupBaseUrl}/${template?.uuid}.jpg`}
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            mockupUUID:
                                                                                template?.uuid,
                                                                            mockupCredits:
                                                                                template?.credits,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                            {template?.credits !==
                                                                0 && (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="#000000"
                                                                    viewBox="0 0 256 256"
                                                                >
                                                                    <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                                </svg>
                                                            )}
                                                        </PBox>
                                                    );
                                                }
                                            )}
                                        </PBox>
                                        <Button
                                            type="primary"
                                            ghost
                                            loading={
                                                this.state.isApplyingMockup
                                            }
                                            onClick={async () => {
                                                if (
                                                    this.state.mockupCredits !==
                                                        0 &&
                                                    this.props.userAccount
                                                        ?.credits === 0
                                                ) {
                                                    this.setState({
                                                        isChangePlanModalOpen: true,
                                                    });
                                                } else {
                                                    await this.setState({
                                                        isApplyingMockup: true,
                                                    });
                                                    message.open({
                                                        type: "loading",
                                                        content:
                                                            "Applying the pattern to template.",
                                                        duration: 0,
                                                    });
                                                    await this.props.generateMockup(
                                                        this.props.pattern
                                                            ?.pattern_uuid,
                                                        this.state.mockupUUID,
                                                        this.state.mockupCredits
                                                    );
                                                    message.destroy();
                                                    await this.props.refetchUser();
                                                    await this.setState({
                                                        isApplyingMockup: false,
                                                    });
                                                }
                                            }}
                                        >
                                            Apply
                                        </Button>{" "}
                                        <Button
                                            type="primary"
                                            ghost
                                            onClick={() => {
                                                download(
                                                    this.props.mockup,
                                                    `img.png`,
                                                    `image/png`
                                                );
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </PBox>
                                </Card>
                            )}
                        </PBox>
                        <PBox className="app-content-crop-and-mockup">
                            <PBox className="app-content-crop">
                                {this.state.control === "design" && (
                                    <ImageEditor
                                        ref={this.editorRef}
                                        usageStatistics={false}
                                        includeUI={{
                                            loadImage: {
                                                // https://zyst.io/how-to-fix-aws-s3-chrome-and-safari-cors-on-images
                                                // https://github.com/nhn/tui.image-editor/issues/68
                                                path:
                                                    this.props.pattern?.url?.replace(
                                                        /^https:\/\//i,
                                                        "http://"
                                                    ) +
                                                    "?t=" +
                                                    new Date().getTime(),
                                                name: "PatternImage",
                                            },
                                            theme: myTheme,
                                            menu: [
                                                "crop",
                                                "flip",
                                                "rotate",
                                                "draw",
                                                "shape",
                                                "icon",
                                                "text",
                                                "mask",
                                                "filter",
                                            ],
                                            initMenu: "filter",
                                            uiSize: {
                                                width: "100%",
                                                height: "800px",
                                            },
                                            menuBarPosition: "bottom",
                                            //colorPalette: ["#ffffff", "#3c3c3c", "#234252"],
                                        }}
                                        cssMaxHeight={500}
                                        cssMaxWidth={700}
                                        selectionStyle={{
                                            cornerSize: 20,
                                            rotatingPointOffset: 70,
                                        }}
                                    />
                                )}
                                {this.state.control === "mockup" && (
                                    <PBox className="app-content-mockup-display">
                                        <PBox className="app-content-mockup-and-pattern">
                                            <PImg
                                                src={this.props.pattern?.url}
                                            />
                                            {this.state.mockupUUID !== "" && (
                                                <PImg
                                                    src={`${mockupBaseUrl}/${this.state.mockupUUID}.jpg`}
                                                />
                                            )}
                                        </PBox>
                                        <PBox className="app-content-mockup-applied">
                                            <PImg src={this.props.mockup} />
                                        </PBox>
                                    </PBox>
                                )}
                                {/*<Crop
                                        src={this.props.pattern.url}
                                        setCroppedImage={(value) => {
                                            this.setState({
                                                croppedImage: value,
                                            });
                                        }}
                                    />*/}
                            </PBox>
                        </PBox>
                        {this.state.isChangePlanModalOpen && (
                            <ChangePlanModal
                                isChangePlanModalOpen={
                                    this.state.isChangePlanModalOpen
                                }
                                handleChangePlanModalOk={async () => {
                                    await this.setState({
                                        isPaymentConfirmModalOpen: true,
                                    });
                                    await this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                handleChangePlanModalCancel={() => {
                                    this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                setNewPlan={(value) => {
                                    this.setState({ newPlan: value });
                                }}
                            />
                        )}

                        {this.state.isPaymentConfirmModalOpen && (
                            <PaymentConfirmModal
                                newPlan={this.state.newPlan}
                                isPaymentConfirmModalOpen={
                                    this.state.isPaymentConfirmModalOpen
                                }
                                handlePaymentConfirmModalOk={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                                handlePaymentConfirmModalCancel={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                            />
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    "& .app-content": {
        display: "grid",
        gap: "1rem",
        alignItems: "flex-start",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp4": {
            gap: "2rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
        padding: "3rem 2rem",
        //maxWidth: "80rem",
        margin: "0 auto",
    },
    "& .app-content-control": {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "& h3": {
            marginTop: 0,
        },
    },
    "& .app-content-crop-and-mockup": {
        "@bp4": {
            gridColumn: "span 3 / span 3",
        },
    },
    "& .app-content-mockups": {
        margin: "1rem 0",
        //border: "1px solid $colors$borderColor",
        overflow: "scroll",
        height: "500px",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "& .app-content-mockups-item": {
            position: "relative",
            "& img": {
                //border: "1px solid $colors$borderColor",
                maxWidth: `calc(100% - 1rem)`,
                //maxHeight: "12rem",
                margin: "0.5rem auto",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
            },
            "& svg": {
                position: "absolute",
                top: "5%",
                right: "5%",
                fill: "#d48806",
                backgroundColor: "#ffe58f",
                padding: "0.125rem 0.25rem",
                borderRadius: "0.25rem",
                width: "1rem",
                height: "1rem",
            },
        },
    },
    "& .mockup-img-active": {
        border: "0.25rem solid $colors$primary",
        padding: "0.25rem",
    },
    "& .app-content-mockup-display": {
        border: "1px solid $colors$borderColor",
        padding: "1rem",
        borderRadius: "0.5rem",
    },
    "& .app-content-mockup-and-pattern": {
        "& img": {
            height: "6rem",
            margin: "0 0.5rem",
        },
    },
    "& .app-content-mockup-applied": {
        display: "flex",
        justifyContent: "center",
        "& img": {
            height: "560px",
            margin: "0.5rem auto",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        pattern: state.patternReducer?.fetchPattern?.pattern,
        mockup: state.patternReducer?.generateMockup?.mockup,
        mockup_templates:
            state.patternReducer?.fetchMockupTemplates?.mockup_templates,
    };
}

export default connect(mapStateToProps, {
    fetchPattern,
    downloadEditedImage,
    generateMockup,
    clearStoredMockup,
    fetchMockupTemplates,
    refetchUser,
})(withRouter(Design));
