import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { Modal, Select, Space, Button } from "antd";
import { PImg, PH4, PBox } from "../theme/BaseTheme";
import { refetchUser } from "../../actions/auth";
import { fetchMockupTemplates } from "../../actions/pattern";
import { mockupBaseUrl, mockupOptions } from "./Constants";

export const MockupTemplateModal = (props) => {
    const [mockupUUID, setMockupUUID] = useState("");
    const [mockupCredits, setMockupCredits] = useState(0);

    useEffect(() => {
        props.fetchMockupTemplates("print");
    }, []);

    return (
        <Modal
            title={"Select A Mockup Template"}
            open={props.isMockupTemplateModalOpen}
            onOk={async () => {
                props.handleMockupTemplateModalOk(mockupUUID, mockupCredits);
            }}
            onCancel={props.handleMockupTemplateModalCancel}
            okText={"Confirm"}
            width={600}
        >
            <PBox css={styles}>
                <Select
                    style={{
                        width: "100%",
                    }}
                    options={mockupOptions}
                    onChange={async (value) => {
                        await props.fetchMockupTemplates(value);
                    }}
                    defaultValue={"print"}
                />
                <PBox className="app-content-mockups">
                    {props.mockupTemplates?.map((template, id) => {
                        return (
                            <PBox className="app-content-mockups-item">
                                <PImg
                                    alt="patternful ai"
                                    className={clsx(
                                        mockupUUID === template?.uuid
                                            ? "mockup-img-active"
                                            : ""
                                    )}
                                    src={`${mockupBaseUrl}/${template?.uuid}.jpg`}
                                    onClick={() => {
                                        setMockupUUID(template?.uuid);
                                        setMockupCredits(template?.credits);
                                    }}
                                    crossorigin={
                                        process.env.NODE_ENV === "development"
                                            ? "anonymous"
                                            : ""
                                    }
                                />
                                {template?.credits !== 0 && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                    >
                                        <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                    </svg>
                                )}
                            </PBox>
                        );
                    })}
                </PBox>
            </PBox>
        </Modal>
    );
};

const styles = {
    "& .app-content-mockups": {
        margin: "1rem 0",
        //border: "1px solid $colors$borderColor",
        overflow: "scroll",
        height: "500px",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "& .app-content-mockups-item": {
            position: "relative",
            "& img": {
                //border: "1px solid $colors$borderColor",
                maxWidth: `calc(100% - 1rem)`,
                //maxHeight: "12rem",
                margin: "0.5rem auto",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
            },
            "& svg": {
                position: "absolute",
                top: "5%",
                right: "5%",
                fill: "#d48806",
                backgroundColor: "#ffe58f",
                padding: "0.125rem 0.25rem",
                borderRadius: "0.25rem",
                width: "1rem",
                height: "1rem",
            },
        },
    },
    "& .mockup-img-active": {
        border: "0.25rem solid #6d28d9",
        padding: "0.25rem",
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth.userAccount,
        mockupTemplates:
            state.patternReducer?.fetchMockupTemplates?.mockup_templates,
    };
}

export default connect(mapStateToProps, {
    refetchUser,
    fetchMockupTemplates,
})(withRouter(MockupTemplateModal));
