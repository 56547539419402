export const mockupOptions = [
    { value: "technology", label: "Technology" },
    { value: "print", label: "Print" },
    { value: "packaging", label: "Packaging" },
    { value: "apparel", label: "Apparel" },
    { value: "home_and_living", label: "Home & Living" },
];

export const mockupBaseUrl =
    "https://s3.us-east-2.amazonaws.com/pattern.bucket.prod.mockups";
