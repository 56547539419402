import { message } from "antd";
import axios from "axios";

// Fetch one mockup template for mockup generator
export const fetchMockupTemplateByUUID = (uuid) => async (dispatch) => {
    await axios
        .get(`/api/mockups/fetch_mockup_template_by_uuid/${uuid}`)
        .then(async (response) => {
            dispatch({
                type: "FETCH_MOCKUP_TEMPLATE_SUCCESS",
                payload: response.data,
            });
        })
        .catch((error) => {
            message.error(error?.response?.data?.message, 5);
        });
};
