import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import download from "downloadjs";
import { Helmet } from "react-helmet";
import { Button, Card, Image, message } from "antd";
import { Layout } from "./Layout";
import { Loader } from "../../components/Loader";
import { PBox, PImg } from "../../theme/BaseTheme";
import {
    downloadEditedImage,
    uploadAndGenerateMockup,
    clearStoredMockup,
} from "../../../actions/pattern";
import { refetchUser } from "../../../actions/auth";
import ChangePlanModal from "../../components/app/ChangePlanModal";
import PaymentConfirmModal from "../../components/app/PaymentConfirmModal";
import MockupTemplateModal from "../../mockup/MockupTemplateModal";
import MockupCropperModal from "../../mockup/MockupCropperModal";
import { fetchMockupTemplateByUUID } from "../../../actions/mockup";
import { mockupBaseUrl } from "../../mockup/Constants";

class MockupApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isApplyingMockup: false,
            mockupUUID: this.props.match.params.uuid
                ? this.props.match.params.uuid
                : "",
            mockupCredits: 0,
            uploadedImage: "",
            fileList: [],
            isChangePlanModalOpen: false,
            isPaymentConfirmModalOpen: false,
            isMockupTemplateModalOpen: false,
            isMockupCropperModalOpen: false,
            newPlan: "",
        };
    }
    async componentDidMount() {
        await this.props.clearStoredMockup();
        if (this.props.match.params.uuid) {
            await this.props.fetchMockupTemplateByUUID(
                this.props.match.params.uuid
            );
            this.setState({ mockupCredits: this.props.mockupTemplate.credits });
        }

        await this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }
        return (
            <Layout>
                <Helmet>
                    <title>Mockup Generator App</title>
                    <meta
                        name="description"
                        content="Create stunning mockups online with one click. Forget about spending hours learning how to use Photoshop. With just one click, you can produce beautiful and near-perfect mockup images."
                    />
                    <meta
                        name="keywords"
                        content="Mockup Generator, Mockup Generator APP, Mockup Online Generator, Patternful, Patternful AI"
                    />
                </Helmet>
                <PBox css={styles}>
                    <PBox className="app-content">
                        <PBox className="app-content-control">
                            <Card title={"Mockup"}>
                                <PBox className="app-content-mockup-template">
                                    <PBox>1. Select a mockup template.</PBox>
                                    {this.state.mockupUUID === "" && (
                                        <PBox className="app-content-mockup-choose-template-button">
                                            <Button
                                                onClick={async () => {
                                                    this.setState({
                                                        isMockupTemplateModalOpen: true,
                                                    });
                                                }}
                                                block
                                            >
                                                Choose Template
                                            </Button>
                                        </PBox>
                                    )}
                                    <PBox className="app-content-mockup-template-thumbnail">
                                        {this.state.mockupUUID !== "" && (
                                            <PBox
                                                onClick={async () => {
                                                    this.setState({
                                                        isMockupTemplateModalOpen: true,
                                                    });
                                                }}
                                            >
                                                <PImg
                                                    src={`${mockupBaseUrl}/${this.state.mockupUUID}.jpg`}
                                                    crossorigin={
                                                        process.env.NODE_ENV ===
                                                        "development"
                                                            ? "anonymous"
                                                            : ""
                                                    }
                                                />
                                                {this.state.mockupCredits !==
                                                    0 && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="#000000"
                                                        viewBox="0 0 256 256"
                                                    >
                                                        <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16Zm-52-56H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-28,16v24H120V152ZM80,164a12,12,0,0,1,12-12h12v24H92A12,12,0,0,1,80,164Zm84,12H152V152h12a12,12,0,0,1,0,24ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Z"></path>
                                                    </svg>
                                                )}
                                            </PBox>
                                        )}
                                    </PBox>
                                </PBox>
                                <PBox className="app-content-mockup-and-pattern">
                                    <PBox>2. Upload a design image.</PBox>
                                    <PBox>
                                        {this.state.uploadedImage === "" && (
                                            <Button
                                                onClick={async () => {
                                                    this.setState({
                                                        isMockupCropperModalOpen: true,
                                                    });
                                                }}
                                                block
                                            >
                                                Upload Design
                                            </Button>
                                        )}
                                        <PBox
                                            className="app-content-mockup-design-thumbnail"
                                            onClick={async () => {
                                                this.setState({
                                                    isMockupCropperModalOpen: true,
                                                });
                                            }}
                                        >
                                            {this.state.uploadedImage && (
                                                <PImg
                                                    src={
                                                        this.state.uploadedImage
                                                    }
                                                />
                                            )}
                                        </PBox>
                                    </PBox>
                                </PBox>
                                <PBox className="app-content-mockup-buttons">
                                    <Button
                                        type="primary"
                                        ghost
                                        loading={this.state.isApplyingMockup}
                                        onClick={async () => {
                                            if (
                                                this.state.uploadedImage === ""
                                            ) {
                                                message.error(
                                                    "Please upload a design image."
                                                );
                                            } else {
                                                if (
                                                    this.state.mockupCredits !==
                                                        0 &&
                                                    !this.props.userAccount
                                                ) {
                                                    window
                                                        .open(
                                                            "/sign-in",
                                                            "_blank"
                                                        )
                                                        .focus();
                                                } else if (
                                                    this.state.mockupCredits !==
                                                        0 &&
                                                    this.props.userAccount
                                                        ?.credits === 0
                                                ) {
                                                    this.setState({
                                                        isChangePlanModalOpen: true,
                                                    });
                                                } else {
                                                    await this.setState({
                                                        isApplyingMockup: true,
                                                    });
                                                    message.open({
                                                        type: "loading",
                                                        content:
                                                            "Applying the pattern to template.",
                                                        duration: 0,
                                                    });
                                                    await this.props.uploadAndGenerateMockup(
                                                        this.state
                                                            .uploadedImage,
                                                        this.state.mockupUUID,
                                                        this.state.mockupCredits
                                                    );
                                                    message.destroy();
                                                    await this.props.refetchUser();
                                                    await this.setState({
                                                        isApplyingMockup: false,
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        Apply
                                    </Button>{" "}
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={() => {
                                            download(
                                                this.props.mockup,
                                                `img.png`,
                                                `image/png`
                                            );
                                        }}
                                    >
                                        Download
                                    </Button>
                                </PBox>
                            </Card>
                        </PBox>
                        <PBox className="app-content-crop-and-mockup">
                            <PBox className="app-content-crop">
                                <PBox className="app-content-mockup-display">
                                    <PBox className="app-content-mockup-applied">
                                        {!this.props.mockup &&
                                            this.state.mockupUUID !== "" && (
                                                <PImg
                                                    src={`${mockupBaseUrl}/${this.state.mockupUUID}.jpg`}
                                                    crossorigin={
                                                        process.env.NODE_ENV ===
                                                        "development"
                                                            ? "anonymous"
                                                            : ""
                                                    }
                                                />
                                            )}
                                        {<Image src={this.props.mockup} />}
                                    </PBox>
                                </PBox>
                            </PBox>
                        </PBox>
                        {this.state.isChangePlanModalOpen && (
                            <ChangePlanModal
                                isChangePlanModalOpen={
                                    this.state.isChangePlanModalOpen
                                }
                                handleChangePlanModalOk={async () => {
                                    await this.setState({
                                        isPaymentConfirmModalOpen: true,
                                    });
                                    await this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                handleChangePlanModalCancel={() => {
                                    this.setState({
                                        isChangePlanModalOpen: false,
                                    });
                                }}
                                setNewPlan={(value) => {
                                    this.setState({ newPlan: value });
                                }}
                            />
                        )}

                        {this.state.isPaymentConfirmModalOpen && (
                            <PaymentConfirmModal
                                newPlan={this.state.newPlan}
                                isPaymentConfirmModalOpen={
                                    this.state.isPaymentConfirmModalOpen
                                }
                                handlePaymentConfirmModalOk={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                                handlePaymentConfirmModalCancel={() =>
                                    this.setState({
                                        isPaymentConfirmModalOpen: false,
                                    })
                                }
                            />
                        )}

                        {this.state.isMockupTemplateModalOpen && (
                            <MockupTemplateModal
                                isMockupTemplateModalOpen={
                                    this.state.isMockupTemplateModalOpen
                                }
                                handleMockupTemplateModalOk={async (
                                    mockupUUID,
                                    mockupCredits
                                ) => {
                                    await this.setState({
                                        isMockupTemplateModalOpen: false,
                                        mockupUUID: mockupUUID,
                                        mockupCredits: mockupCredits,
                                    });
                                }}
                                handleMockupTemplateModalCancel={() => {
                                    this.setState({
                                        isMockupTemplateModalOpen: false,
                                    });
                                }}
                            />
                        )}
                        {this.state.isMockupCropperModalOpen && (
                            <MockupCropperModal
                                isMockupCropperModalOpen={
                                    this.state.isMockupCropperModalOpen
                                }
                                handleMockupCropperModalOk={(croppedImage) => {
                                    this.setState({
                                        isMockupCropperModalOpen: false,
                                        uploadedImage: croppedImage,
                                    });
                                    console.log(
                                        "uploadedImage: ",
                                        this.state.uploadedImage
                                    );
                                }}
                                handleMockupCropperModalCancel={() => {
                                    this.setState({
                                        isMockupCropperModalOpen: false,
                                    });
                                }}
                            />
                        )}
                    </PBox>
                </PBox>
            </Layout>
        );
    }
}

const styles = {
    maxWidth: "80rem",
    marginLeft: "auto",
    marginRight: "auto",
    "& .app-content": {
        display: "grid",
        gap: "1rem",
        alignItems: "flex-start",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "@bp4": {
            gap: "2rem",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        },
        padding: "3rem 2rem",
        //maxWidth: "80rem",
        margin: "0 auto",
    },
    "& .app-content-control": {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "& h3": {
            marginTop: 0,
        },
    },
    "& .app-content-crop-and-mockup": {
        "@bp4": {
            gridColumn: "span 3 / span 3",
        },
    },
    "& .app-content-mockup-template": {
        marginBottom: "1rem",
        "& img": {
            maxHeight: "6rem",
            maxWidth: "6rem",
            margin: "auto 0.5rem",
            borderRadius: "0.5rem",
        },
        "& > div": {
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
        },
        "& .app-content-mockup-choose-template-button": {
            width: "100%",
        },
        "& .app-content-mockup-template-thumbnail": {
            width: "100%",
            display: "flex",
            justifyContent: "start",
            "& div": {
                position: "relative",
                "&:hover": {
                    cursor: "pointer",
                },
            },
            "& svg": {
                position: "absolute",
                top: "-5%",
                right: "0%",
                fill: "#d48806",
                backgroundColor: "#ffe58f",
                padding: "0.125rem 0.25rem",
                borderRadius: "0.25rem",
                width: "1rem",
                height: "1rem",
            },
        },
    },
    "& .app-content-mockups": {
        margin: "1rem 0",
        //border: "1px solid $colors$borderColor",
        overflow: "scroll",
        height: "500px",
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        "& .app-content-mockups-item": {
            position: "relative",
            "& img": {
                //border: "1px solid $colors$borderColor",
                maxWidth: `calc(100% - 1rem)`,
                //maxHeight: "12rem",
                margin: "0.5rem auto",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
            },
            "& svg": {
                position: "absolute",
                top: "5%",
                right: "5%",
                fill: "#d48806",
                backgroundColor: "#ffe58f",
                padding: "0.125rem 0.25rem",
                borderRadius: "0.25rem",
                width: "1rem",
                height: "1rem",
            },
        },
    },
    "& .mockup-img-active": {
        border: "0.25rem solid $colors$primary",
        padding: "0.25rem",
    },
    "& .app-content-mockup-display": {
        border: "1px solid $colors$borderColor",
        padding: "1rem",
        borderRadius: "0.5rem",
    },
    "& .app-content-mockup-and-pattern": {
        marginTop: "1rem",
        marginBottom: "1rem",
        "& span": { display: "block" },
        "& img": {
            maxHeight: "6rem",
            maxWidth: "6rem",
            margin: "auto 0.5rem",
            borderRadius: "0.5rem",
            "&:hover": {
                cursor: "pointer",
            },
        },
        "& > div": {
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
        },
    },
    "& .app-content-mockup-design-thumbnail": {
        width: "100%",
        display: "flex",
        justifyContent: "start",
    },
    "& .app-content-mockup-applied": {
        display: "flex",
        justifyContent: "center",
        "& img": {
            height: "560px",
            margin: "0.5rem auto",
        },
    },
    "& .app-content-mockup-buttons": {
        marginTop: "2rem",
        "& button": {
            marginRight: "0.5rem",
        },
    },
};

function mapStateToProps(state) {
    return {
        userAccount: state.authReducer?.userAuth?.userAccount,
        mockup: state.patternReducer?.generateMockup?.mockup,
        mockupTemplate:
            state.mockupReducer?.fetchMockupTemplate?.mockup_template,
    };
}

export default connect(mapStateToProps, {
    downloadEditedImage,
    uploadAndGenerateMockup,
    clearStoredMockup,
    refetchUser,
    fetchMockupTemplateByUUID,
})(withRouter(MockupApp));
